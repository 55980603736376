import React from "react";
import { Link, graphql } from "gatsby";
import { FaYenSign } from "react-icons/fa";
import { FaTrain } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import Layout from "../layouts/index";
import NewBlogList from "../components/NewBlogList";
import BackgroundImg from "../images/in-front-of-clinic.jpg";
import TokkyuImg from "../images/tokkyu_600.jpg";
import monshinImg from "../images/monshin_600.jpg";
import DeskBackgroundImg from "../images/desk_background.jpg";
import DirectorImg from "../images/director.jpg";

const style = {
  bg: {
    backgroundColor: "gray",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
};

const BannerStyle = {
  ...style.bg,
  color: "white",
  backgroundImage: `url("${BackgroundImg}")`,
};

const bunnerFeatureStyle = { fontSize: 24 };
const Banner = () => (
  <div style={BannerStyle}>
    <div style={{ padding: "96px 18px", position: "relative" }}>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ color: "white" }}>渋谷の健康診断はヒラハタクリニック</h1>
        <div style={bunnerFeatureStyle}>日曜・祝日でも受診可能</div>
        <div style={bunnerFeatureStyle}>受診者１人ごとに消毒</div>
        <div style={bunnerFeatureStyle}>渋谷駅から最短徒歩２分</div>
      </div>
      <div className="top-subbanner-area">
        <div>
          <a
            href="https://questionaire.hirahata.net/input_jusin.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ width: 200, margin: 10, paddingBottom: 0 }}
              src={`${monshinImg}`}
            />
          </a>
        </div>
      </div>
    </div>
  </div>
);

const Feature = () => (
  <div className="container">
    <div style={{ padding: "60px 0" }}>
      <div style={{ textAlign: "center", marginBottom: 50 }}>
        <p
          style={{
            marginTop: 40,
            marginBottom: 40,
            color: "#c60000",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {/* 緊急おしらせ */}
          大変恐縮ではございますが、12/14(土)～12/18(水)まで担当者不在の為、健診予約の受付は行っておりません。
          <br />
          診療はしておりますので、予約済の方はご来院ください。
          <br />
          病院窓口での予約は対応しておりませんので予めご了承ください。
        </p>
        <h2>
          渋谷駅近くの{" "}
          <Link to="https://www.hirahata-clinic.or.jp/">
            ヒラハタクリニック
          </Link>
          まで
          <br />
          定期健診や企業健診などお気軽にご相談下さい。
        </h2>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div>
            <div className="d-flex justify-content-center">
              <FaYenSign style={{ fontSize: 50, color: "#008275" }} />
            </div>
            <div className="d-flex justify-content-center">
              <h3>費用（料金）</h3>
            </div>
          </div>
          <div>
            定期健康診断、生活習慣病健診、人間ドックがご利用いただけます。
            ニーズに合わせた各種カスタマイズが可能です。 詳しくは、
            <Link to="/fee2024">料金ページ</Link>をご確認下さい。
          </div>
        </div>
        <div className="col-sm-4">
          <div>
            <div className="d-flex justify-content-center">
              <FaTrain style={{ fontSize: 50, color: "#008275" }} />
            </div>
            <div className="d-flex justify-content-center">
              <h3>アクセス</h3>
            </div>
          </div>
          <div>
            渋谷駅出口より最短徒歩2分の位置にあります。詳しくは
            <Link to="/about-us">アクセス欄</Link>を御覧ください。
          </div>
        </div>
        <div className="col-sm-4">
          <div>
            <div className="d-flex justify-content-center">
              <FaUserMd style={{ fontSize: 50, color: "#008275" }} />
            </div>
            <div className="d-flex justify-content-center">
              <h3>充実の設備</h3>
            </div>
          </div>
          <div>
            鼻から診る苦痛の少ない胃カメラやデジタル式の高画質なレントゲン装置、腹部エコー、乳腺エコーの他、頚動脈エコー、心エコー、甲状腺エコー等を検査可能なエコー検査装置を設備しております。
            詳しくは、<Link to="/about-us/equipments">設備紹介ページ</Link>
            を御覧ください。
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Notice = () => (
  <div className="container">
    <div style={{ padding: "60px 0", textAlign: "center" }}>
      <h3>当院で健康診断・人間ドックを受けられる方へ</h3>
      <div>
        <p style={{ marginTop: 20 }}>
          <span style={{ color: "#c60000", fontWeight: 600 }}>
            咳・発熱等の症状などのある方は、健康診断や人間ドックをお受け頂くことができません。
            <br />
            また、現在症状が出ていなくても、症状が出た日から2週間以上はあけてご受診下さい。
          </span>
          <br />
          詳しくは来院前に電話やメールなどで必ずご相談ください。
          <br />
          症状がある状態で来院された場合は、当日の健診等をお断りさせていただきますのでご了承ください。
          <br />
        </p>
        <p style={{ marginTop: 40 }}>
          <span style={{ color: "#c60000", fontWeight: 600 }}>
            2024年 4月より健康診断の価格が改定されます。
            <br />
          </span>
          改定後の料金は下記ページをご覧ください。
          <br />
          <Link
            to="/fee2024"
            style={{
              fontSize: 18,
              margin: "0 5px",
              borderRadius: 0,
              height: 40,
            }}
            className="btn btn-success"
            role="button"
          >
            2024年度以降 新料金ページ
          </Link>
        </p>
      </div>
    </div>
  </div>
);

const ContactStyle = {
  mainContainer: {
    ...style.bg,
    color: "white",
    backgroundImage: `url("${DeskBackgroundImg}")`,
  },
  btn: {
    borderRadius: 0,
    margin: 5,
  },
};

const Contact = () => (
  <div style={ContactStyle.mainContainer}>
    <div className="container" style={{ padding: "80px 0" }}>
      <h3 style={{ margin: "20px 0", color: "white" }} className="text-center">
        お問い合わせ
      </h3>
      <div style={{ margin: "20px 0" }} className="text-center">
        <div>TEL : 03-6419-7056</div>
        <div>Email : info@hirahata-clinic.or.jp</div>
        <div>Address : 東京都渋谷区渋谷1-24-6 マトリクス・ツービル9F</div>
      </div>
      <div className="d-flex justify-content-center flex-wrap">
        <a
          href="tel:0364197056"
          style={ContactStyle.btn}
          className="btn btn-success"
          role="button"
        >
          電話で問い合わせ
        </a>
        <Link
          to="/about-us/contact"
          style={ContactStyle.btn}
          className="btn btn-light"
          role="button"
        >
          フォームで問い合わせ
        </Link>
      </div>
    </div>
  </div>
);

const News = ({ data }) => (
  <div className="container">
    <div style={{ padding: "60px 0" }}>
      <h3>最新情報</h3>
      <NewBlogList data={data} />
    </div>
  </div>
);

const RecommendStyle = {
  backgroundColor: "#f5f5f5",
};

const Recommend = () => (
  <div style={RecommendStyle}>
    <div className="container">
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ padding: "25px 0" }}
      >
        <h4 style={{ fontSize: 18, margin: "0 5px", lineHeight: "40px" }}>
          コロナ後遺症やワクチン長期副反応が心配な方　>>>
        </h4>
        <Link
          to="/fee#kouishou"
          style={{ fontSize: 18, margin: "0 5px", borderRadius: 0, height: 40 }}
          className="btn btn-success"
          role="button"
        >
          新型コロナ後遺症検診
        </Link>
      </div>

      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ padding: "25px 0" }}
      >
        <h4 style={{ fontSize: 18, margin: "0 5px", lineHeight: "40px" }}>
          健診結果をはやく受け取りたい方　>>>
        </h4>
        <Link
          to="/fee#op"
          style={{ fontSize: 18, margin: "0 5px", borderRadius: 0, height: 40 }}
          className="btn btn-success"
          role="button"
        >
          当日結果お渡しオプション
        </Link>
      </div>

      {/* <div
      className="d-flex justify-content-center flex-wrap"
      style={{ padding: "25px 0" }}
      >
      <h4 style={{ fontSize: 18, margin: "0 5px", lineHeight: "40px" }}>
      申し込みから健診結果受け取りまでとにかく急いでる方>>>
      </h4>
      <a href="https://questionaire.hirahata.net/"
      target="_blank" style={{ fontSize: 18, margin: "0 5px", borderRadius: 0, height: 40 }}
      className="btn btn-success"
      role="button"
      >
      健診特急コース
      </a>
      </div> */}
    </div>
  </div>
);

const Hi = () => (
  <div className="container">
    <div style={{ padding: "60px 0" }}>
      <h3>挨拶</h3>
      <div className="row">
        <div className="col-sm-4">
          <img className="mx-auto d-block" src={`${DirectorImg}`} />
        </div>
        <div className="col-sm-8">
          当院（ヒラハタクリニック）は1985年開院。
          大手企業を含め、渋谷駅周辺の多くの企業・団体様の健診･人間ドックを請け負わせていただき、実績を積み重ねてまいりました。
          また、夜間の健診や内視鏡検査、女性技師による乳腺エコーなど、多くのメニューをご用意。
          現在、当院で健診を受けられる方は、年間８千人(契約企業様は800社以上)を数えております。
          高い専門性、充実したアフターフォロー、高い利便性、リーズナブルな料金が当院の健診･ドックの特徴。ぜひ、ご検討ください。
        </div>
      </div>
    </div>
  </div>
);

const IndexPage = ({ data }) => (
  <Layout>
    <div>
      <Banner />
      <Feature />
      <Notice />
      <Contact />
      <News data={data} />
      <Recommend />
      <Hi />
    </div>
  </Layout>
);

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "//news//" } }
      limit: 5
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY年MM月DD日")
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
